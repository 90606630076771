
import SliderTabs from '@/components/SliderTabs.vue'
import { EcosystemId } from '@/ecosystem'

import Vue from 'vue'
import { mapActions } from 'vuex'
import AwaitLock from 'await-lock'
import { ethers } from 'ethers'

import {
  LAUNCHPAD_TOKEN_FACTORY_ABI,
  LAUNCHPAD_TOKEN_DEPLOYER_ABI,
  ERC20_ABI,
  APPROVE_AMOUNT,
} from '@/constants'
import { ChainId, TokenModel, IEcosystem } from '@/ecosystem'
import { delay } from '@/utils'

// These token symbols will not be allowed
const symbolBlacklist = ['TOAD', 'PAD', 'USDC', 'USDT', 'DAI', 'BNB', 'BUSD', 'ETH', 'BTC', 'GLMR', 'MOVR', 'XRP', 'XMR', 'DOT', 'ADA', 'SOLAR']

function countDecimals(n: number) {
  const nString = n.toString()
  if (!nString.includes('.')) {
    return 0
  } else {
    return nString.split('.')[1].length
  }
}

export default Vue.extend({
  components: { SliderTabs },
  data: () => ({
    active: true,

    getToadLinks: {
    "bsc": "/bsc/swap?outputCurrency=0x463e737d8f740395abf44f7aac2d9531d8d539e9",
    "moonriver": "/moonriver/swap?outputCurrency=0x165DBb08de0476271714952C3C1F068693bd60D7",
    "moonbeam": "/moonbeam/swap?outputCurrency=0xF480f38C366dAaC4305dC484b2Ad7a496FF00CeA"
    },

    // These are used to make the form work, not used anywhere else
    formStep: 1,
    maxFormStep: 5,

    // Filled in the form
    tokenName: '',
    tokenSymbol: '',
    tokenSupply: '',
    tokenDecimals: '18',
    tokenType: <"basic" | "reflection" | ''> '',

    // Only relevant if tokenType is "reflection"
    transactionFee: '2',
    burnFee: '0',
    liquidityFee: '0',
    devFee: '0',

    // Created token address
    tokenContractAddress: '',

    tokenCreated: false, // Set to true when the token has been created

    syncLock: new AwaitLock(),
    feeTokenAllowance: <ethers.BigNumber | null> null
  }),
  computed: {
    ecosystemId: {
      get(): EcosystemId {
        return this.$store.state.ecosystemId
      },
      set(val: EcosystemId) {
        this.$store.commit('setEcosystemId', val)
      }
    },
    ecosystem(): IEcosystem {
      return this.$store.getters.ecosystem
    },
    address(): string {
      return this.$store.state.address
    },
    web3(): ethers.Signer | null {
      return this.$store.state.web3
    },
    chainId(): ChainId {
      return this.$store.getters.ecosystem.chainId
    },
    multicall(): ethers.providers.Provider {
      return this.$store.getters.multicall
    },
    canContinue(): boolean {
      if (this.formStep == 2) {
        return this.tokenType != ''
      } else if (this.formStep == 3) {
        return this.formErrors === false
      }
      return true
    },
    formErrors(): string | false {
      var errors = ''

      if (!this.tokenName) { errors += 'Token name is required\n' }
      else if (this.tokenName.length > 20) { errors += 'Token name cannot be longer than 20 characters\n' }

      if (!this.tokenSymbol) { errors += 'Token symbol is required\n' }
      else if (this.tokenSymbol.length > 8) { errors += 'Token symbol cannot be longer than 8 characters\n' }
      else if (this.tokenSymbol != this.tokenSymbol.toUpperCase()) { errors += 'Token symbol must consist of uppercase characters\n' }
      else if (symbolBlacklist.includes(this.tokenSymbol) ) { errors += 'Please don\'t create tokens that falsely represent other projects\n' }

      if (!this.tokenSupply) { errors += 'Choose the max supply of your token\n' }
      else if (parseFloat(this.tokenSupply) > 1000000000000) { errors += 'Token supply too high\n' }
      else if (parseFloat(this.tokenSupply) < 10) { errors += 'Token supply too low\n' }
      else if (parseFloat(this.tokenSupply) % 1 != 0 || !(/^\d+$/.test(this.tokenSupply)) ) { errors += 'Token supply must be a whole number\n' }

      if (!this.tokenDecimals) { errors += 'Choose the number of decimals of your token\n' }
      else if (parseFloat(this.tokenDecimals) < 0 || parseFloat(this.tokenDecimals) > 255 ) { errors += 'Choose a number between 0 and 255\n' }
      else if (parseFloat(this.tokenDecimals) % 1 != 0 || !(/^\d+$/.test(this.tokenDecimals)) ) { errors += 'Token decimals must be a whole number\n' }

      if (this.tokenType == "reflection") {
        const transactionFeeStatus = this.getFeeValidationStatus(this.transactionFee, 'transaction')
        if (transactionFeeStatus) {
          errors += transactionFeeStatus
        }

        const burnFeeStatus = this.getFeeValidationStatus(this.burnFee, 'burn')
        if (burnFeeStatus) {
          errors += burnFeeStatus
        }

        const liquidityFeeStatus = this.getFeeValidationStatus(this.liquidityFee, 'liquidity')
        if (liquidityFeeStatus) {
          errors += liquidityFeeStatus
        }

        const developmentFeeStatus = this.getFeeValidationStatus(this.devFee, 'development')
        if (developmentFeeStatus) {
          errors += developmentFeeStatus
        }

        if (this.totalReflectionFees > 30) {
          errors += 'Total fees cannot be greater than 30%\n'
        }

        if (this.totalReflectionFees == 0) {
          errors += 'Total fees must be greater than 0.'
        }
      }

      if (errors.length == 0) {
        return false
      }
      return errors
    },
    tokenModel(): TokenModel {
      if (this.tokenType == "reflection") {
        return TokenModel.Reflections
      } else {
        return TokenModel.Standard
      }
    },
    tokenModelContractAddress(): string | undefined {
      return this.ecosystem.launchPadTokenFactoryModels[this.tokenModel]
    },
    tokenFactoryContract(): ethers.Contract | null {
      if (!this.ecosystem.launchPadTokenFactoryAddress) {
        return null
      }

      return new ethers.Contract(
        this.ecosystem.launchPadTokenFactoryAddress,
        LAUNCHPAD_TOKEN_FACTORY_ABI,
        this.ecosystem.dataseed
      )
    },
    // approval state for TOAD creation fee
    isFeeApproved(): boolean {
      // TODO: read creationFee from contract
      return this.feeTokenAllowance !== null && this.feeTokenAllowance.gt(1e18.toString())
    },
    totalReflectionFees(): number {
      return (parseFloat(this.transactionFee) || 0) +
        (parseFloat(this.burnFee) || 0) +
        (parseFloat(this.liquidityFee) || 0) +
        (parseFloat(this.devFee) || 0)
    }
  },
  async mounted() {
    while (true) {
      try {
        this.sync()
      } catch (e) {
        console.error(e)
      }

      await delay(5000)
    }
  },
  beforeRouteLeave(to, from, next) {
    this.active = false
    next()
  },
  beforeDestroy() {
    this.active = false
  },
  methods: {
    copyText (text : string) {
      let textArea = document.createElement("textarea")
      textArea.value = text
      textArea.style.top = "0"
      textArea.style.left = "0"
      textArea.style.position = "fixed"
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      let successful = document.execCommand('copy')

      document.body.removeChild(textArea)
    },
    formatNumberWithCommas(nbr : any) {
      if (!nbr) {
        return '0.0'
      }
      return nbr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    advanceForm(delta : number) {
      this.formStep += delta
      if (this.formStep < 1) 
        this.formStep = 1
      if (this.formStep > this.maxFormStep)
        this.formStep = this.maxFormStep
    },
    async approve() {
      if (!this.web3) {
        await this.requestConnect()
        return
      }

      const feeTokenContract = await this.getFeeTokenContract()
      const tx = await feeTokenContract.populateTransaction.approve(this.ecosystem.launchPadTokenFactoryAddress, APPROVE_AMOUNT)
      await this.safeSendTransaction({ tx, targetChainId: this.chainId })
      await this.sync()
    },
    getTokenModelArguments(tokenModel: TokenModel) {
      if (tokenModel == TokenModel.Standard) {
        return ethers.utils.defaultAbiCoder.encode(
          ['string', 'string', 'uint8', 'uint256'],
          [
            this.tokenName,
            this.tokenSymbol,
            parseInt(this.tokenDecimals),
            ethers.BigNumber.from(this.tokenSupply)
          ]
        )
      } else if (tokenModel == TokenModel.Reflections) {
        return ethers.utils.defaultAbiCoder.encode(
          ['string', 'string', 'uint8', 'uint256', 'uint256', 'uint256', 'uint256', 'uint256'],
          [
            this.tokenName,
            this.tokenSymbol,
            parseInt(this.tokenDecimals),
            ethers.BigNumber.from(this.tokenSupply),
            Number(this.transactionFee) * 10,
            Number(this.liquidityFee) * 10,
            Number(this.burnFee) * 10,
            Number(this.devFee) * 10
          ]
        )
      }

      throw new Error()
    },
    async submit() {
      if (!this.web3) {
        await this.requestConnect()
        return
      }

      const tokenFactoryContract = this.tokenFactoryContract!.connect(this.web3)
      const tokenDeployerContract = new ethers.Contract(this.tokenModelContractAddress!, LAUNCHPAD_TOKEN_DEPLOYER_ABI)

      const args = this.getTokenModelArguments(this.tokenModel)
      const tx = await tokenFactoryContract.populateTransaction.createToken(this.tokenModelContractAddress, args)
      const txReceipt: ethers.providers.TransactionReceipt | false = await this.safeSendTransaction({ tx, targetChainId: this.chainId })
      if (txReceipt) {
        for (const log of txReceipt.logs) {
          try {
            const logDesc = tokenDeployerContract.interface.parseLog(log)
            if (logDesc.name == 'CreateToken') {
              this.tokenContractAddress = logDesc.args.tokenAddress
              this.tokenCreated = true
            }
          } catch {
          }
        }
      }
    },
    async getFeeTokenContract() {
      const feeToken = await this.tokenFactoryContract!.feeToken()
      return new ethers.Contract(feeToken, ERC20_ABI, this.ecosystem.dataseed)
    },
    async sync() {
      if (!this.tokenFactoryContract || !this.address) {
        return
      }

      await this.syncLock.acquireAsync()
      try {
        const tokenFactoryContract = this.tokenFactoryContract
        const address = this.address
        const feeTokenContract = await this.getFeeTokenContract()
        const allowance = await feeTokenContract.allowance(address, tokenFactoryContract.address)
        if (tokenFactoryContract === this.tokenFactoryContract && address === this.address) {
          this.feeTokenAllowance = allowance
        }
      } finally {
        this.syncLock.release()
      }
    },
    getFeeValidationStatus(fee: string, feeName: string): false | string {
      if (fee === '') {
        return `Specify a ${feeName} fee\n`
      }

      const feeNumber = Number(fee)
      if (isNaN(feeNumber)) {
        return `The ${feeName} fee is not a valid number\n`
      }
      if (feeNumber < 0) {
        return `The ${feeName} fee cannot be negative\n`
      }
      if (countDecimals(feeNumber) > 1) {
        return `The ${feeName} fee cannot have more than 1 decimal places\n`
      }

      return false
    },
    ...mapActions(['requestConnect', 'safeSendTransaction'])
  },
  watch: {
    tokenFactoryContract() {
      this.feeTokenAllowance = null
      setTimeout(() => this.sync())
    },
    address() {
      this.feeTokenAllowance = null
      setTimeout(() => this.sync())
    }
  }
})
